import React from 'react'
import styles from './organization.module.scss';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Organization from './organization';
import Navbar from '../../home/navbar/navbar';
import Footer from '../../home/footer/footer';
import images from '../../../assets/images/images';
import { Field, Form, Formik } from 'formik';

const UpdateOrganization = ({templateId}) => {
    const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };    
  return (
    <div style={{ backgroundColor: '#FFFFFF' }} className='orgNav'>
    <Box sx={{ width: '100%', typography: 'body1' }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            <Tab label="Org Info" value="1" />
            <Tab label="Page Header" value="2" />
            <Tab label="Page Footer" value="3" />
          </TabList>
        </Box>
        <TabPanel value="1">
            <Organization templateId={templateId}/>
        </TabPanel>
        <TabPanel value="2">
          <div className='navWrapper'>
            <Navbar />
            
          </div>

          <Formik
                // initialValues={}
                onSubmit={async (values) => {
                    console.log(values)
                }}
                enableReinitialize
                >
                <Form>

                
          <div className={styles.formContainer}>
            <div className={styles.fieldContainer}>
                <label>Organization Name</label>
                <Field id="orgname" name="orgname" placeholder=""/>
            </div>
          </div>
          <div className={styles.headerNavLinks}>
              <h3>Navigation Links</h3>
              <table>
                <thead>
                  <tr>
                    <th>Navigation Link Name</th>
                    <th>Link</th>
                    <th>Navigation Type</th>
                    <th width='100'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Developer</td>
                    <td>https://api.payorc.com/</td>
                    <td>Text Link</td>
                    <td>
                      <div className={styles.actionBtns}>
                          <ul>
                              <li><a className={styles.edit}><img src={images.EDIT} width="20" alt="Edit" /></a></li>
                              <li><a className={styles.delete}><img src={images.DELETE} width="20" alt="Delete" /></a></li>
                          </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Login</td>
                    <td>https://api.payorc.com/</td>
                    <td>Text Link</td>
                    <td>
                      <div className={styles.actionBtns}>
                          <ul>
                              <li><a className={styles.edit}><img src={images.EDIT} width="20" alt="Edit" /></a></li>
                              <li><a className={styles.delete}><img src={images.DELETE} width="20" alt="Delete" /></a></li>
                          </ul>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>Sign Up</td>
                    <td>https://api.payorc.com/</td>
                    <td>Button</td>
                    <td>
                      <div className={styles.actionBtns}>
                          <ul>
                              <li><a className={styles.edit}><img src={images.EDIT} width="20" alt="Edit" /></a></li>
                              <li><a className={styles.delete}><img src={images.DELETE} width="20" alt="Delete" /></a></li>
                          </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className={styles.flags}>
              <h3>Flags</h3>

              <table>
                <thead>
                  <tr>
                    <th>Flag</th>
                    <th>Flag Nation</th>
                    <th width='100'>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><img src={images.INDIA} alt="uae" width="26"/></td>
                    <td>India</td>
                    <td>
                      <div className={styles.actionBtns}>
                          <ul>
                              <li><a className={styles.edit}><img src={images.EDIT} width="20" alt="Edit" /></a></li>
                              <li><a className={styles.delete}><img src={images.DELETE} width="20" alt="Delete" /></a></li>
                          </ul>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

            </div>
            </Form>
            </Formik>
        </TabPanel>
        <TabPanel value="3">
            <Footer />
        </TabPanel>
      </TabContext>
    </Box>
    </div>
  )
}

export default UpdateOrganization