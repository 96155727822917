import React, { useEffect } from 'react'
import styles from './optimizesteps.module.scss'
import images from '../../../assets/images/images'
import axios from 'axios';
import { APIConstant } from '../../../services/APIConstant';

const OptimizeSteps = ({template}) => {
  const [pageTitleData, setPageTitleData] = React.useState();
  const [pageSectionData, setpageSectionData] = React.useState({});
    useEffect(() => {
        axios.post(APIConstant.getJourneyTitle, {templateId : template})
        .then(res => {
          if(res.data.length > 0){
            setPageTitleData(res.data[0]);
          }
        })
        .catch(err => console.log(err))
    }, [])

    useEffect(() => {
      axios.post(APIConstant.getJourneySection, {templateId : template})
      .then(res => {
        console.log(res.data)
        if(res.data.length > 0){
          setpageSectionData(res.data[0]);
        }
      })
      .catch(err => console.log(err))
  }, [])
  return (
    <div className={styles.optimizeSteps}>
      <div className='container'>
       
        <h2 className={styles.title} data-aos="fade-up">{pageTitleData?.title}</h2>
        <p className={styles.subtitle} data-aos="fade-up">{pageTitleData?.description}</p>
        
        <div className={styles.grid_cont} data-aos="fade-up">
          <div className={styles.gridTxtSec}>
            <h3>{pageSectionData.lcri_title}</h3>
            {/* <p>{pageSectionData.lcri_description}</p> */}
            <div dangerouslySetInnerHTML={ {__html: pageSectionData.lcri_description} }/>
          </div>
          <div className={styles.gridImgSec}>
              <img src={pageSectionData.lcri_img} alt="Banner" />
          </div>
        </div>

        <div className={styles.grid_cont} data-aos="fade-up">
          <div className={styles.gridImgSec}>
            <img src={pageSectionData.lirc_img} alt="Banner" />
          </div>      
          <div className={styles.gridTxtSec}>
            <h3>{pageSectionData.lirc_title}</h3>
            {/* <p>{pageSectionData.lirc_description}</p> */}
            <div dangerouslySetInnerHTML={ {__html: pageSectionData.lirc_description} }/>
          </div>
          
        </div>
      </div>
    </div>
  )
}

export default OptimizeSteps