import React, { useEffect, useState } from 'react'
import { Field, Formik, Form } from 'formik';
import styles from './nocode.module.scss'
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';
import RichTextEditor from '../../../../RichTextEditor/RichTextEditor';

const UpdateNocodeTitle = ({templateId}) => {
  const [initialValue, setInitialValue] = useState();
  const [titleVal, setTitleVal] = useState();
  const [descriptionData, setDescriptionData] = useState('');
  useEffect(() => {
    fetch(APIConstant.getNoCodeIntegrationTitle, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ templateId : templateId})
    })
        .then(res => res.json())
        .then((data) => {
          setTitleVal(data)
          if(data.length > 0){
            
            setInitialValue({
              nocodeid: data[0].id,
              title: data[0].title,
              description: data[0].description,
              status: data[0].status,
              visibility: data[0].visibility
            })

            setDescriptionData(data[0].description)
          }else{
            setInitialValue({
              title: '',
              description: '',
              status: '',
              visibility: ''
            })
          }
        })
	    .catch(err => console.log(err))
  }, [])

  const descriptionTextEditorData = (descriptionData) => {
    setDescriptionData(descriptionData)
  }

  return (
    <div className={styles.updatePayStack}>
      {console.log(titleVal)}
        <Formik
                initialValues={initialValue}
                  onSubmit={async (values) => {
                    values.description = descriptionData;
                    if(titleVal.length > 0){
                      console.log('Update')
                      axios.put(APIConstant.updateNoCodeTitle, values)
                      .then(res => {
                        console.log(res)
                      })
                      .catch(err => console.log(err))
                    }else{
                      console.log('add')
                      values.templateId = templateId
                      axios.post(APIConstant.addNoCodeIntegrationTitle, values)
                      .then(res => {
                        console.log(res)
                      })
                      .catch(err => console.log(err))
                    }
                    
                  }}
                enableReinitialize
                >
                <Form>

                <header className={styles.formHeader}>
                    <h5>Edit Heading Text</h5>
                    <div className={styles.btnContainer}>
                      {titleVal?.length > 0
                        ? <button className={styles.primary} variant="contained" type='submit'>Update</button>
                        : <button className={styles.primary} variant="contained" type='submit'>Add</button>
                      }
                    </div>

                  </header>
                    <div className={styles.formContainer}>
                        <div className="fieldContainer">
                            <label htmlFor="title"> Title</label>
                            <Field id="title" name="title" />
                        </div>
                        <div className="fieldContainer">
                            <label htmlFor="description"> Description</label>
                            {/* <Field id="description" name="description"  /> */}
                            <RichTextEditor getChildData={descriptionTextEditorData} parentData={descriptionData}/>
                        </div>
                        
                    </div>
                  {/* <div className={styles.btnContainer}>
                    {titleVal?.length > 0
                      ? <button variant="contained" type='submit' className={styles.secondary}>Update</button>
                      : <button variant="contained" type='submit' className={styles.secondary}>Add</button>
                    }
                  </div> */}
                </Form>
            </Formik>


    </div>
  )
}

export default UpdateNocodeTitle