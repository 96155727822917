import React, { useEffect, useState } from 'react'
import { Field, Formik, Form } from 'formik';
import styles from './paymentstack.module.scss'
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';
import RichTextEditor from '../../../../RichTextEditor/RichTextEditor';

const UpdateTitle = ({templateId}) => {
  const [initialValue, setInitialValue] = useState();
  const [titleData, setTitleData] = useState([]);
  const [descriptionData, setDescriptionData] = useState('');
  useEffect(() => {
     fetch(APIConstant.getPortfolioTitle, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ templateId : templateId})
    })
			.then(res => res.json())
			.then((data) => {
        setTitleData(data)
        if(data.length > 0){
          setInitialValue({
            title: data[0].title,
            description: data[0].description,
            status: data[0].status,
            visibility: data[0].visibility
          })
          setDescriptionData(data[0].description)
        }
        else{
          setInitialValue({
            title: '',
            description: '',
            status: '',
            visibility: ''
          })
        }
      })
			.catch(err => console.log(err))
  }, [])
  const descriptionTextEditorData = (descriptionData) => {
    setDescriptionData(descriptionData)
  }
  return (
    <div className={styles.updatePayStack}>
        <Formik
                initialValues={initialValue}
                  onSubmit={async (values) => {
                    values.templateId = templateId
                    values.description = descriptionData;
                    if(titleData.length > 0){
                      axios.put(APIConstant.updatePortfolioTitle, values)
                      .then(res => {
                        console.log(res)
                      })
                      .catch(err => console.log(err))
                    }else{
                      axios.post(APIConstant.addPortfolioTitle, values)
                      .then(res => {
                        console.log(res)
                      })
                      .catch(err => console.log(err))
                    }
                  }}
                enableReinitialize
                >
                <Form>
                  <header className={styles.formHeader}>
                    <h5>Edit Heading Text</h5>
                    <div className={styles.btnContainer}>
                      {titleData.length > 0 
                        ? <button className={styles.primary} variant="contained" type='submit'>Update</button>
                        : <button className={styles.primary} variant="contained" type='submit'>Add</button>
                      }
                    </div>
                  </header>
                    <div className={styles.formContainer}>
                        <div className="fieldContainer">
                            <label htmlFor="title"> Title</label>
                            <Field id="title" name="title" />
                        </div>
                        <div className="fieldContainer">
                            <label htmlFor="description"> Description</label>
                            {/* <Field id="description" name="description"  /> */}
                            <RichTextEditor getChildData={descriptionTextEditorData} parentData={descriptionData}/>
                        </div>
                    </div>
                {/* <div className={styles.btnContainer}>
                  {titleData.length > 0 
                    ? <button variant="contained" type='submit'>Update</button>
                    : <button variant="contained" type='submit'>Add</button>
                  }
                    
                </div> */}
                </Form>
            </Formik>


    </div>
  )
}

export default UpdateTitle