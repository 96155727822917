import React, { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import Developers from '../pages/developers/developers';
import AdminHome from '../pages/admin/admin';
import AboutUs from '../pages/aboutus/aboutus';
import Login from '../pages/auth/login/login';
import SignUp from '../pages/auth/signup/signup';
import Enquiry from '../components/admin/enquiry/enquiry';
import Organization from '../components/admin/organization/organization';
import Theme from '../components/admin/theme/theme';
import HomeUpdate from '../components/admin/home/home';
import Home from '../pages/home/home';
import NavUpdate from '../components/admin/home/sections/nav/nav';
import FooterUpdate from '../components/admin/home/sections/footer/footerupdate';
import PricingUpdate from '../components/admin/home/sections/pricing/pricingupdate'
// import NoCode from '../components/admin/home/sections/nocode/nocode'
import PaymentStack from '../components/admin/home/sections/paymentstack/paymentstack'
import Journey from '../components/admin/home/sections/journey/journey'
import Platforms from '../components/admin/home/sections/platforms/platforms'
import BannerUpdate from '../components/admin/home/sections/banners/bannerupdate'
import IntegrationMadeEasyUpdate from '../components/admin/home/sections/integrationmadeeasy/integrationmadeeasy';
import Legal from '../pages/legal/legal';
import LegalHome from '../components/home/legal/legal';
import PagePreview from '../pages/preview/preview';
import UpdateOrganization from '../components/admin/organization/updateOrganization';
import PolicyHome from '../pages/policy/policy';
import CompanyHome from '../pages/company/company';
import { APIConstant } from '../services/APIConstant';
import DynamicPageRenderer from '../components/preview/dynamicPage/dynamicPage';
import ContactUs from '../pages/contactus/contactus';
import PageNotFound from '../pages/pageNotFound/pageNotFound';

function Router() {
  const [publishedPageList, SetPublishedPageList] = useState([]);
  const [loader, setLoader] = useState(true);
  useEffect(() => {
    setLoader(true)
    fetch(APIConstant.getPublishedPageList, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    .then(res => res.json())
    .then((data) => {
      setLoader(false)
      SetPublishedPageList(data)
    })
    .catch(err => setLoader(false))
  }, [])
  return (
    <>
    {console.log('publishedPageList', publishedPageList)}
    <Routes>
      <Route path="/" element={<Home />}></Route>
      {publishedPageList.length > 0 &&
        publishedPageList.map((item) => 
          <Route path={`/${item.page_url}`} element={<DynamicPageRenderer pageid={item.page_id}/>}></Route>
        )
        
      }
      <Route path='/developers' element={<Developers />}></Route>
      <Route path='/aboutus' element={<AboutUs />}></Route>
      <Route path='/login' element={<Login />}></Route>
      <Route path='/legal' element={<LegalHome />}></Route>
      <Route path='/signup' element={<SignUp />}></Route>
      <Route path='/policy' element={<PolicyHome />}></Route>
      {/* <Route path='/company' element={<CompanyHome />}></Route> */}
      <Route path='/company/contact' element={<ContactUs />}></Route>
      <Route path='/admin' element={<AdminHome />}>
        <Route index path='organization' element={<UpdateOrganization />}></Route>
        
        <Route path='enquiry' element={<Enquiry />}></Route>
        <Route path='theme' element={<Theme />}></Route>
        <Route path='preview' element={<PagePreview />}></Route>
        <Route path='home' element={<HomeUpdate />}>
          <Route path='nav' element={<NavUpdate />}></Route>
          <Route path='banner' element={<BannerUpdate />}></Route>
          <Route path='platforms' element={<Platforms />}></Route>
          <Route path='journey' element={<Journey />}></Route>
          <Route path='paystack' element={<PaymentStack />}></Route>
          <Route path='integration' element={<IntegrationMadeEasyUpdate />}></Route>
          <Route path='pricing' element={<PricingUpdate />}></Route>
          <Route path='footer' element={<FooterUpdate />}></Route>
        </Route>
        <Route path='aboutus' element={<AboutUs />}></Route>
        <Route index path='legal' element={<Legal />}></Route> 
      </Route>
      {!loader &&
        <Route path="*" element={<PageNotFound />} />
      }
      
    </Routes>
    </>
  );
}

export default Router;
