import React, { useEffect, useState } from 'react'
import PhoneInput from 'react-phone-number-input'
import styles from './contactus.module.scss'
import axios from 'axios';
import Navbar from '../../components/home/navbar/navbar';
import Footer from '../../components/home/footer/footer';
import { useLocation } from 'react-router-dom';
import { Field, Form, Formik } from 'formik';
import { APIConstant } from '../../services/APIConstant';
// import { Turnstile } from '@marsidev/react-turnstile'
import { Snackbar } from '@mui/material';
import FullPageLoader from '../../components/loader/loader';
// import { useTurnstile } from 'react-turnstile';
import Turnstile, { useTurnstile } from "react-turnstile";






const ContactUs = () => {
    const { state } =  useLocation();
    const [ phoneNumber, setPhoneNumber ] = useState()
    const [ disabledBtn, setDisabledBtn ] = useState(true)
    const [ phoneErr, setPhoneErr ] = useState()
    const { email } = state || '';
    const [showLoader, setShowLoader] = useState(false)
    const [phoneCountryCode, phoneCountryCodeSetter] = useState('IN');
    const turnstile = useTurnstile();
   
    const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  
  const Phone = ({ field, form, ...props }) => {
    return (
      <PhoneInput
        international
        defaultCountry= {phoneCountryCode}
        onCountryChange={(v) => phoneCountryCodeSetter(v)}
        onChange={value => {
          if (!form.touched[field.name]) form.setFieldTouched(field.name);
          form.setFieldValue(field.name, value);
          
        }}
      />
    );
  };

    const validateFullname = (value) => {
        let error;
        if (!value) {
            error = 'Enter Full Name';
            setDisabledBtn(true)
       }
       return error;
    }
    
    const validateSubject = (value) => {
        let error;
        if (!value) {
            error = 'Select Subject';
            setDisabledBtn(true)
       }
       return error;
    } 
    
    const validateMessage = (value) => {
        console.log('messag', value)
        let error;
        if (!value) {
            error = 'Enter Your Message';
            setDisabledBtn(true)
       }
       return error;
    } 
    const validateEmail = (value) => {
        let error;
        if (!value) {
          error = 'Enter email address';
          setDisabledBtn(true)
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)) {
          error = 'Invalid email address';
          setDisabledBtn(true)
        }
        return error;
    } 

    
    
    const validatePhone = (value) => {
        console.log('Phone', phoneCountryCode)
        let error;
        if (!value) {
            error = 'Enter Phone Number';
            setDisabledBtn(true)
            
       }else{
        if(phoneCountryCode === 'IN'){
            console.log('Length', value.length)
            if(value.length !== 13){
                error = 'Please Enter 10 digit number';
              
                setDisabledBtn(true)
            }
        }
       }
       return error;
    }
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className={styles.contactus}>
            <div className={styles.mobileHeader} style={{backgroundColor: '#398378', height: '90px'}}>
                <Navbar />
            </div>
            <div className={`${styles.effect5}`} style={{ width: '760px', maxWidth: '96%', margin: '0px auto' }}>
                <div className={`${styles.contactusWrapper} `}>
                    <h3>Contact Form </h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    {showLoader &&
                        <FullPageLoader />
                    }
                    <Formik
                        initialValues={{
                            fullname: '',
                            emailid: email,
                            businessname: '',
                            subject: '',
                            message: ''
                        }}
                        
                        onSubmit={async (values, { resetForm }) => {
                            console.log('Contact us ', values)
                            values.mobilenumber = phoneNumber;
                            setShowLoader(true)
                            fetch(APIConstant.sendContactusFormData, {
                                method: 'POST',
                                headers: {
                                  'Accept': 'application/json',
                                  'Content-Type': 'application/json'
                                },
                                body: JSON.stringify(values)
                            })
                            .then(res => res.json())
                            .then((data) => {
                                if(data.message === 'success'){
                                    
                                }
                                setShowLoader(false)
                            })
                            .catch(err => console.log(err))

                            resetForm();
                        }}
                        
                        >
                            {({ errors, touched, isValidating, formik }) => (
                                
                                <Form>
                                    <div className={styles.formWrapper}>
                                        <div className={styles.fieldWrapper}>
                                            <span>*</span>
                                            <Field id="fullname" validate={validateFullname} placeholder="Full Name" name="fullname" />
                                            {errors.fullname && touched.fullname && <div className='error'>{errors.fullname}</div>}
                                        </div>
                                        <div className={styles.fieldWrapper}>
                                            <span>*</span>
                                            <Field
                                            id="emailid"
                                            name="emailid"
                                            type="emailid"
                                            placeholder="Email Id"
                                            validate={validateEmail}
                                            />
                                            {errors.emailid && touched.emailid && <div className='error'>{errors.emailid}</div>}
                                        </div>
                                        {/* <div className={`${styles.fieldWrapper} phoneField`}>
                                            <Field validate={() => validatePhone(phoneNumber)} name="phone" id="phone">
                                                {() => {
                                                    return (
                                                        <PhoneInput
                                                            international
                                                            defaultCountry="IN"
                                                            value={phoneNumber}
                                                            onChange={setPhoneNumber}
                                                            
                                                        />
                                                    );
                                                }}
                                            </Field>
                                            {phoneErr &&
                                                <div className='error' >{phoneErr}</div>
                                            }
                                        </div> */}
                                        <div className={`${styles.fieldWrapper} phoneField`}>
                                            <span>*</span>
                                            <Field name="phone" component={Phone} validate={validatePhone}/>
                                            {errors.phone && touched.phone && <div className='error'>{errors.phone}</div>}
                                        </div>
                                        <div className={styles.fieldWrapper}>
                                            <Field id="businessname" name="businessname" placeholder="Business Name" />
                                        </div>
                                        <div className={styles.fieldWrapper}>
                                             <span>*</span>
                                            <Field as="select" name="subject" placeholder="Choose Subject" validate={validateSubject}>
                                                <option value="">Select Subject</option>
                                                <option value="s1">Reaching Sales Team</option>
                                                <option value="s2">Become a Referrel Partner</option>
                                                <option value="s3">I am a PSP/PG want to Integrate</option>
                                                <option value="s4">Product improvement suggestion</option>
                                                <option value="s5">Proposing a Business Idea</option>
                                                <option value="s6">I am a Service Vendor</option>
                                                <option value="s7">Reporting a Complaint</option>
                                                <option value="s8">Others</option>
                                            </Field>
                                            {errors.subject && touched.subject && <div className='error'>{errors.subject}</div>}
                                        </div>
                                        <div className={styles.fieldWrapperLarge} >
                                            <span>*</span> 
                                            <Field as="textarea" name="message" placeholder="Your Message" validate={validateMessage}/>
                                            {errors.message && touched.message && <div className='error'>{errors.message}</div>}
                                        </div>
                                    </div>
                                    

                                    
                                    
                            
                                    <Turnstile sitekey='0x4AAAAAAAzc4TjHRfLf56s7' onVerify={(token) => {
                                        fetch("/login", {
                                        method: "POST",
                                        body: JSON.stringify({ token }),
                                        }).then((response) => {
                                        if (!response.ok) turnstile.reset();
                                        });
                                    }}/>
                                <div className={styles.btnContainer}>
                                           {console.log('Errors', touched)}
                                                <button type="submit" disabled={Object.keys(errors).length === 0 && Object.keys(touched).length !== 0 ? false : true}>Submit</button>
                                            
                                       
                                    </div>
                                </Form>
                            )}
                        </Formik>

                        
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default ContactUs