import React, { useState, useEffect } from 'react';
import styles from './cookie_policy.module.scss'
import Cookies from 'js-cookie'; // Optional, for easier cookie management

const CookiePolicy = () => {
    const [isVisible, setIsVisible] = useState(false);
  const [userConsent, setUserConsent] = useState(null);

  useEffect(() => {
    // Check if the cookie consent has been set
    const consent = Cookies.get('cookieConsent');
    
    if (consent !== undefined) {
      // If cookieConsent is already set, no need to show the banner
      setUserConsent(consent);
    } else {
      // Show the banner if the consent is not yet set
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    Cookies.set('cookieConsent', 'accepted', { expires: 365 }); // Store consent for a year
    setUserConsent('accepted');
    setIsVisible(false);
  };

  const handleReject = () => {
    Cookies.set('cookieConsent', 'rejected', { expires: 365 });
    setUserConsent('rejected');
    setIsVisible(false);
  };

  if (userConsent !== null) return null; // Don't show the banner if user has made a choice

  return (
    
    isVisible && (
    <div className={styles.cookie_wrapper}>
        
        <div className="container">
            <div className={styles.cookie_policy}>
                <div className={styles.cookie_content}>
                    <h4>Cookie Consent</h4>
                    <p>We use cookies to enhance your browsing experience, serve personalized ads or content, and analyze our traffic. By clicking "Accept", you consent to our use of cookies. <a>Read More</a></p>
                </div>
                <div className={styles.cookie_btn}>
                    <ul>
                        <li><button onClick={handleReject} className={styles.btn_bordered}>Decline</button></li>
                        <li><button onClick={handleAccept} className={styles.btn_solid}>Accept All</button></li>
                    </ul>
                </div>
            </div>
        </div>
      
    </div>
    )

  )
}

export default CookiePolicy




