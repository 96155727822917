import React, { useEffect, useState } from 'react'
import { Field, Formik, Form } from 'formik';
import styles from './lircTwoCol.module.scss'
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';
import FileUpload from '../../../../FileUpload/FileUpload';
import RichTextEditor from '../../../../RichTextEditor/RichTextEditor';

const UpdateLircTwoColTitle = ({templateId, pageViewMode}) => {
  const [initialValue, setInitialValue] = useState();
  const [titleVal, setTitleVal] = useState();
  const [imgPath1, setImagePath1] = useState();
  const [descriptionData, setDescriptionData] = useState('');

  useEffect(() => {
    fetch(APIConstant.getLircTwoColTitle, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ templateId : templateId})
    })
        .then(res => res.json())
        .then((data) => {
          setTitleVal(data)
          if(data.length > 0){
            
            setInitialValue({
              id: data[0].id,
              title: data[0].title,
              description: data[0].description,
              status: data[0].status,
              imgPath: data[0].img_path
            })
            setDescriptionData(data[0].description)
          }else{
            setInitialValue({
              title: '',
              description: '',
              status: ''
            })
          }
        })
	    .catch(err => console.log(err))
  }, [])
  const descriptionTextEditorData = (descriptionData) => {
    setDescriptionData(descriptionData)
  }
  return (
    <div className={styles.updatePayStack}>
      {console.log(titleVal)}
        <Formik
                initialValues={initialValue}
                  onSubmit={async (values) => {
                    values.description = descriptionData;
                    if(titleVal.length > 0){
                      if(imgPath1){
                        values.imgPath = imgPath1
                      }else{
                        values.imgPath = initialValue.imgPath
                      }
                      console.log('Update')
                      axios.put(APIConstant.updateLircTwoColtitle, values)
                      .then(res => {
                        if(res.data.message === 'success'){
                          // setShowLoader(false)
                          pageViewMode('preview')
                        }
                      })
                      .catch(err => console.log(err))
                    }else{
                      console.log('add')
                      values.templateId = templateId
                      values.imgPath = imgPath1
                      axios.post(APIConstant.addLircTwoColTitle, values)
                      .then(res => {
                        if(res.data.message === 'success'){
                          // setShowLoader(false)
                          pageViewMode('preview')
                        }
                      })
                      .catch(err => console.log(err))
                    }
                    
                  }}
                enableReinitialize
                >
                <Form>
                  <header className={styles.formHeader}>
                    <h5>{titleVal?.length > 0
                          ? 'Update '
                          : 'Add '
                      } LIRC2C Text</h5>
                    <div style={{marginTop: '0px'}} className={styles.btnContainer}>
                        {titleVal?.length > 0
                          ? <button className={styles.primary} variant="contained" type='submit'>Update</button>
                          : <button className={styles.primary} variant="contained" type='submit'>Add</button>
                        }
                    </div>
                  </header>
                    <div className={styles.formContainer}>
                        <div className="fieldContainer">
                            <label htmlFor="title"> Title</label>
                            <Field id="title" name="title" />
                        </div>
                        <div className="fieldContainer">
                            <label htmlFor="description"> Description</label>
                            {/* <Field id="description" name="description"  /> */}
                            <RichTextEditor getChildData={descriptionTextEditorData} parentData={descriptionData}/>
                        </div>
                        <div className="fieldContainer" style={{width: '500px'}}>
                          <FileUpload setImagePath={setImagePath1} />
                        </div>
                    </div>
                </Form>
            </Formik>


    </div>
  )
}

export default UpdateLircTwoColTitle