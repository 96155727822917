import React, { useEffect, useState } from 'react'
import { Formik, Field, Form } from 'formik';
import styles from './journey.module.scss';
import axios from 'axios';
import { APIConstant } from '../../../../../services/APIConstant';
import FileUpload from '../../../../FileUpload/FileUpload';
import RichTextEditor from '../../../../RichTextEditor/RichTextEditor';

const UpdateJourneyGrid = ({ templateId }) => {

    const [imgPath1, setImagePath1] = useState()
    const [imgPath2, setImagePath2] = useState()
    const [viewMode, setViewMode] = useState('')
    const [selectedId, setSelectedId] = useState('')
    const [descriptionDataLcri, setDescriptionDataLcri] = useState('');
    const [descriptionDataLirc, setDescriptionDataLirc] = useState('');

    const [initialValues, setInitialValues] = useState({});
    useEffect(() => {
         fetch(APIConstant.getJourneySection, {
            method: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ templateId : templateId})
        })
        .then(res => res.json())
        .then((data) => {
            console.log('Data :', data)
            if(data.length > 0){
                setViewMode('update')
                setSelectedId(data[0].id)
                setInitialValues({
                    title_lcri: data[0].lcri_title,
                    description_lcri: data[0].lcri_description,
                    lcri_img: data[0].lcri_img,
                    title_lirc: data[0].lirc_title,
                    description_lirc: data[0].lirc_description,
                    lirc_img: data[0].lirc_img,
                })
                setDescriptionDataLcri(data[0].lcri_description)
                setDescriptionDataLirc(data[0].lirc_description)
            }else{
                setViewMode('add')
                setInitialValues({
                    title_lcri: '',
                    description_lcri: '',
                    title_lirc: '',
                    description_lirc: '',
                    lcri_img: data[0].lcri_img,
                    lirc_img: data[0].lirc_img
                })
            }
        })
        .catch(err => console.log(err))
    }, [])

    const descriptionTextEditorDataLcri = (descriptionData) => {
        setDescriptionDataLcri(descriptionData)
    }

    const descriptionTextEditorDataLirc = (descriptionData) => {
        setDescriptionDataLirc(descriptionData)
    }

    return (
        <div className={styles.journeyGrid}>

            <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                   
                    values.templateId = templateId
                    values.description_lcri = descriptionDataLcri;
                    values.description_lirc = descriptionDataLirc;

                    console.log(values)
                    if(viewMode === 'add'){
                        values.lcri_img = imgPath1
                        values.lirc_img = imgPath2
                        axios.post(APIConstant.addJourneySection, values)
                        .then(res => {
                        console.log(res)
                        })
                        .catch(err => console.log(err))
                    }
                    if(viewMode === 'update'){
                        values.id = selectedId;
                        if(imgPath1){
                            values.lcri_img = imgPath1 
                        }else{
                            values.lcri_img = initialValues.lcri_img
                        }

                        if(imgPath2){
                            values.lirc_img = imgPath2
                        }else{
                            values.lirc_img = initialValues.lirc_img
                        }
                        
                        axios.put(APIConstant.updateJourneySection, values)
                        .then(res => {
                        console.log(res)
                        })
                        .catch(err => console.log(err))
                    }
                }}
                enableReinitialize
            >
                <Form>
                        <header className={styles.formHeader}>
                            <h5>Edit Sub-Section</h5>
                            <div className={styles.btnContainer}>
                                <button type="submit" className={styles.primary}>Submit</button>
                            </div>
                        </header>
                    <div className={styles.journeyGridCont}>
                        
                        <div className={styles.lcri}>
                            <h4>LCRI</h4>
                            <label htmlFor="title_lcri">Title</label>
                            <Field id="title_lcri" name="title_lcri" placeholder="" />

                            <label htmlFor="description_lcri">Description</label>
                            {/* <Field id="description_lcri" name="description_lcri" placeholder="" /> */}
                            <RichTextEditor getChildData={descriptionTextEditorDataLcri} parentData={descriptionDataLcri}/>
                            {/* <div className={styles.imgUpload}>
                                <Field id="icon" name="icon" type="file" onChange={(e) => { uploadImage1(e);}}/>

                                <img src={baseImage1} height="30" alt="Icon" />
                            </div> */}
                             <FileUpload setImagePath={setImagePath1} />
                        </div>

                        <div className={styles.lirc}>
                            <h4>LIRC</h4>
                            <label htmlFor="title_lirc">Title</label>
                            <Field id="title_lirc" name="title_lirc" placeholder="" />

                            <label htmlFor="description_lirc">Description</label>
                            {/* <Field id="description_lirc" name="description_lirc" placeholder="" /> */}
                            <RichTextEditor getChildData={descriptionTextEditorDataLirc} parentData={descriptionDataLirc}/>
                            {/* <div className={styles.imgUpload}>
                                <Field id="icon" name="icon" type="file" onChange={(e) => { uploadImage2(e);}}/>

                                <img src={baseImage2} height="30" alt="Icon" />
                            </div> */}
                             <FileUpload setImagePath={setImagePath2} />
                        </div>
                    </div>
                    {/* <div className={styles.btnCont}>
                        <button type="submit">Submit</button>
                    </div> */}
                    
                </Form>
            </Formik>
        </div>
    )
}

export default UpdateJourneyGrid