import React, { useEffect, useState } from 'react'
import { APIConstant } from '../../services/APIConstant';
import { NavLink } from 'react-router-dom';

const CompanyList = () => {
    const [pages, setPages] = useState([]);
    const [companyPages, setCompanyPages] = useState([]);

    useEffect(() => {
    fetch(APIConstant.getPageList)
        .then(res => res.json())
        .then((data) => {
            setPages(data)

            
        })
        .catch(err => console.log(err))
    }, [])

    useEffect(() => {
        setCompanyPages(pages?.filter(item => item.page_category === 'marketing' && item.status === 'published'))

        
    }, [pages])
    console.log('DATA', companyPages)
  return (
    <div>
        {/* {console.log('Policy',policyPages)} */}
        {companyPages.map((item,i) => {
            return(
                <>
                    {i === 0 &&
                    // <li key={i}><NavLink target='_blank' to={`/${item.page_url}`}>{item.page_name}</NavLink></li>
                    <li key={i}><NavLink target='_blank' to={`/${item.page_url}`}>{item.page_name}</NavLink></li>
                    }
                </>
            )}
        )}
    </div>
  )
}

export default CompanyList