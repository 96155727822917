import React, { useEffect, useState } from 'react'
import styles from './home.module.scss'
import Navbar from '../../components/home/navbar/navbar'
import Banner from '../../components/home/banner/banner'
import Portfolio from '../../components/home/portfolio/portfolio'
import ManageandOptimize from '../../components/home/manageandoptimize/manageand_optimize'
import CookiePolicy from '../../components/home/cookiepolicy/cookie_policy'
import Footer from '../../components/home/footer/footer'
import OmniChannel from '../../components/home/omnichannel/omni_channel'
import Pricing from '../../components/home/pricing/pricing'
import TrustedCompanies from '../../components/home/trustedcompanies/trusted_companies'
import IntegrationMadeEasy from '../../components/home/integrationmadeeasy/integration_made_easy'
import OptimizeSteps from '../../components/home/optimizesteps/optimizesteps'
import { APIConstant } from '../../services/APIConstant'
import axios from 'axios'
import LIRC from '../../components/home/lirc/lirc'
import LCRI from '../../components/home/lcri/lcri'
import LircTwoColumn from '../../components/home/lirc-two-col/lirc-two-col'
import RoundRobinRibbon from '../../components/home/roundrobinribbon/roundrobinribbon'
import Processor from '../../components/home/processor/processor'
import { Helmet } from 'react-helmet'

const Home = () => {
   const [pageSections, setPageSections] = useState([]); 
  // const [bannerStatus, setBannerStatus] = useState(''); 
  // const [setion01, setSection01] = useState(''); 
  // const [setion02, setSection02] = useState(''); 
  // const [setion03, setSection03] = useState(''); 
  // const [setion04, setSection04] = useState(''); 
  // const [setion05, setSection05] = useState(''); 
  // const [setion06, setSection06] = useState(''); 
  // const [setion07, setSection07] = useState(''); 
  
    useEffect(() => {
      // axios.get(APIConstant.getSectionVisibility)
      // .then(res => {
      //   setBannerStatus(res.data.find(obj => obj.visibility_id === 1).visible_status);
      //   setSection01(res.data.find(obj => obj.visibility_id === 2).visible_status);
      //   setSection02(res.data.find(obj => obj.visibility_id === 3).visible_status);
      //   setSection03(res.data.find(obj => obj.visibility_id === 4).visible_status);
      //   setSection04(res.data.find(obj => obj.visibility_id === 5).visible_status);
      //   setSection05(res.data.find(obj => obj.visibility_id === 6).visible_status);
      //   setSection06(res.data.find(obj => obj.visibility_id === 7).visible_status);
      //   setSection07(res.data.find(obj => obj.visibility_id === 8).visible_status);
      // })
      // .catch(err => console.log(err))

      axios.get(APIConstant.getLandingPageContent)
      .then(res => {
        setPageSections(res.data)
      })
      .catch(err => console.log(err))

    }, [])

  return (
    <>
      
        <Navbar />
        <div>
        {/* {bannerStatus === 'active' &&
          <Banner />
        }
        {setion01 === 'active' &&
          <ManageandOptimize />
        }
        {setion02 === 'active' &&
          <OptimizeSteps />
        }
        {setion03 === 'active' &&
          <Portfolio />
        }
        {setion04 === 'active' &&
          <OmniChannel />
        }
        {setion05 === 'active' &&
          <IntegrationMadeEasy />
        }
        {setion06 === 'active' &&
          <Pricing />
        }
        {setion07 === 'active' &&
          
        } */}
        <h1 style={{width: '0px', height:'0px', opacity: 0, position: 'absolute'}}>Accept Payments online, Payments Orchestration, Collect Payments online, Payment Gateway, PSP
        </h1>
       </div>
       <Helmet>
        {/* <title>Payorc.com | Accept Payments online | Built for Conversion | Payments Orchestrator for Payment Gateways, PSPs, Banks and online ecommerce merchants</title>
        <meta name="description" content="Collecting payments online from any part of the world using universe of payment Methods and We provide high Conversion, low Drop-Offs, low transaction Cost, reduced transaction Risk with High systems Availability."/>
        <meta name="keywords" content="Accept Payments online, Payments Orchestration, Collect Payments online, Payment Gateway, PSP"/>
        <link rel="canonical" href="https://payorc.com"/> */}
       </Helmet>


          {pageSections?.length && pageSections?.map((item, index) => {
            return (
              <>
                {item.template_id === 1 &&
                  <Banner template={item.id} />
                }
                {item.template_id === 2 &&
                  <ManageandOptimize template={item.id} />
                }
                {item.template_id === 3 &&
                  <OptimizeSteps template={item.id} />
                }
                {item.template_id === 4 &&
                  <Portfolio template={item.id} />
                }
                {item.template_id === 5 &&
                  <OmniChannel template={item.id} />
                }
                {item.template_id === 6 &&
                  <IntegrationMadeEasy template={item.id} />
                }
                {item.template_id === 7 &&
                  <Pricing templateId={item.id} />
                }
                {item.template_id === 8 &&
                  <LIRC templateId={item.id} />
                }
                {item.template_id === 9 &&
                  <LCRI templateId={item.id} />
                }
                {item.template_id === 10 &&
                  <LircTwoColumn template={item.id} />
                }
                {item.template_id === 11 &&
                  <RoundRobinRibbon templateId={item.id} />
                }
                {item.template_id === 12 &&
                  <Processor templateId={item.id} />
                }
              </>
            )}
          )}




        <Footer />
        <CookiePolicy />
    </>
  )
}

export default Home